ul.list{
  list-style-type: disc;
  li::marker {
    color: red;
  }
}
.App {  
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.max-w-1440 {
  max-width: 1440px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.legal-container {
  width: 90%;
  margin: 0 auto;
}
.poap-legal {
  padding: 80px 0;
}
.text-custom {
  font-size: 0.75rem/* 12px */;
  line-height: 1rem/* 16px */;
}
@font-face {
  font-family: "flytrap";
  src: url("./fonts/FlyTrap\ Extended.otf") format("truetype");
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Thin.ttf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "onest";
  src: url("./fonts/Onest-Black.ttf") format("truetype");
  font-weight: 900;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.bg-grey {
  --tw-bg-opacity: 1;
  background-color: #32332f;
}
.bg-send{
  --tw-bg-opacity: 1;
  background-color: #a98c6a;
}
.bg-grey1 {
  --tw-bg-opacity: 1;
  background-color: #1f1f1f;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.shadowpoap {
  box-shadow:
    rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.poap-hero-background {
  background-image: url("../src/static/SFONDO-LANDING-POAP.png");
  background-blend-mode: multiply;
}

